<template>
  <div class="tuijian" v-show="list.length > 0">
    <div class="tuijian-title" v-if="title.length > 0">{{ title }}</div>
    <ul>
      <li class="linkApp" v-for="(item, index) in list" :key="index">
        <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
          <script type="text/wxtag-template">
            <style>
              .btn{
                width: 100%;
                height: 100px;
              }
            </style>
              <div class="btn"></div>
          </script>
        </wx-open-launch-app>
        <div class="li" @click="handleTuijian()">
          <div class="tuijian-left">
            <p class="tuijian-content">
              {{ item.content }}
            </p>
            <p class="tuijian-source">{{ item.source }}</p>
          </div>
          <img :src="item.src" class="tuijian-right" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "tuijian",
    props: {
      list: {
        type: [Array, Object],
        default() {
          return [];
        },
      },
      title: {
        type: String,
        default: "",
      },
      isCzh: {
        type: Boolean,
        default: false,
      },
      path: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        default: "",
      },
    },
    data() {
      return {
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      handleTuijian(item) {
        if (!this.isCzh) {
          this.linkApp(item);
        } else {
          this.$emit("link", item);
        }
      },
      openError() {
        this.$router.push({ name: "index" });
      },
    },
  };
</script>

<style lang="scss" scoped>
  //推荐阅读
  .tuijian {
    width: 92%;
    height: auto;
    overflow: hidden;
    margin: 20px auto;
    .tuijian-title {
      padding: 20px 0;
      font-size: 20px;
      font-weight: 800;
      color: #1f1f1f;
    }
    ul {
      li {
        position: relative;
      }
      .li {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        .tuijian-left {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 10px;
          .tuijian-content {
            font-size: 18px;
            line-height: 1.5;
            color: #1f1f1f;
            width: 100%;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            // height: 1.1rem;
          }
          .tuijian-source {
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            color: #9c9c9c;
          }
        }
        .tuijian-right {
          flex: none;
          width: 30%;
          height: 100px;
          overflow: hidden;
          border-radius: 12px;
          object-fit: cover;
        }
        &:nth-last-child(1) {
          border: none;
        }
      }
    }
  }
</style>
